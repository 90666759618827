<template>
  <div class="overview-station">电站大屏</div>
</template>

<script>
  export default {};
</script>

<style lang="scss">
  .overview-station {
    color: inherit;
  }
</style>
